import notifierSignal from 'signals/notifier.signal';

const displayError = ({
  message,
  title,
  timeOut = 7000,
}) => {
  notifierSignal.update({
    message,
    title,
    timeOut,
  });
};

export default displayError;
