import { Modal, Button, Nav, Image } from 'react-bootstrap';
import $user from 'signals/User.signal';

const AppModal = ({
  show,
  handleClose,
  headerText,
  bodyText,
  buttonText,
  handleSubmit,
  hrefLink,
  hrefText,
  image,
  varient,
  children,
}) => (
  <Modal
    data-bs-theme={$user.value?.mode === 'dark-mode' ? 'dark' : 'light'}
    className={$user.value?.mode ?? 'dark-mode'}
    show={show}
    onHide={handleClose}
  >
    <Modal.Header className="bg-base-tertiary" closeButton />
    <Modal.Title className="bg-base-tertiary">
      {headerText && (
        headerText
      )}
      {image && (
      <Image className="w-100" src={image} alt="img" height="40px" />
      )}
    </Modal.Title>
    <Modal.Body className="bg-base-tertiary">
      {children}
      {bodyText && (
        bodyText
      )}
      {hrefLink && hrefText && (
      <Nav.Link href={hrefLink} className={`text-decoration-underline border-${varient} text-${varient}`}>{hrefText}</Nav.Link>
      )}
    </Modal.Body>
    {handleSubmit && (
      <Modal.Footer className="bg-base-tertiary">
        <Button variant={varient} onClick={handleSubmit} className={`bg-${varient}`}>
          {buttonText}
        </Button>
      </Modal.Footer>
    )}
  </Modal>
);

export default AppModal;
