import Fetcher from 'libs/fetcher';
import { formatMoneyNoDecimals } from 'utils/formatMoney';
import $user from 'signals/User.signal';
import displayError from 'utils/displayError';
import Signal from 'libs/signals';
import moment from 'moment';
import { Popover } from 'react-bootstrap';
import { getBaseTodos } from '../../../constants';

const fetcher = new Fetcher();

export const $founderDealShared = Signal({});

export const $founderDealSharedUI = Signal({
  isModalVisible: false,
  tab: 'Deal Snapshot',
});

export const TABS = ['Deal Snapshot', 'Company Info', 'File Room'];

export const handleFormChange = ({ name, value }) => $founderDealShared.update({ [name]: value });

export const fetchAndSetData = async (id) => {
  const { organization: userOrg } = $user.value;
  try {
    $founderDealShared.loadingStart();
    const orgId = userOrg?.investor_org_id ?? 0;

    const [data, dealExistsInPipeline] = await Promise.all([
      fetcher.get({ path: `/deals/${id}/public` }),
      fetcher.get({ path: `/dealsoninvestorpipeline/${id}/${orgId}/public` }),
    ]);

    if (data) {
      const { founder_org: { organization } } = data;

      const founder = organization?.users?.filter((person) => person?.roles?.includes(1))?.[0];
      const coFounders = organization?.users?.filter((person) => person?.roles?.includes(2));

      const mainAddress = organization?.addresses?.filter(item => !!item.is_main_address);
      const countryLocated = mainAddress?.[0]?.country_code;

      $founderDealShared.update({
        id: +id,
        ...data,
        raiseSize: formatMoneyNoDecimals(data?.current_raise_size),
        prevRaised: formatMoneyNoDecimals(data?.amount_previously_raised),
        valLastRound: formatMoneyNoDecimals(data?.valuation_last_round),
        minimumInvestment: formatMoneyNoDecimals(data?.minimum_investment),
        investors: data?.investors_on_founder_pipeline?.map(item => `${item?.investor_org?.organization?.name}`).join(', '),
        organization: {
          ...organization,
          founder: founder ? `${founder?.first_name} ${founder?.last_name}` : '',
          coFounders: coFounders?.map(user => `${user?.first_name} ${user?.last_name}`).join(', '),
          location: countryLocated,
          startup_stage: data?.founder_org?.startup_stage,
        },
        dealExistsInPipeline,
        documents: data?.documents || [],
        isInvestor: Boolean(userOrg?.type_id === 2),
        isFounder: Boolean(userOrg?.type_id === 1),
        isAngel: Boolean(userOrg?.investor_org?.investor_type === 'Angel'),
      });

      if (organization?.id !== userOrg?.id) {
        const { id: userId, first_name: first, last_name: last } = $user.value;

        const newView = {
          userId: userId ?? 0,
          firstName: first ?? 'Anonymous',
          lastName: last ?? '',
          orgId: userOrg?.id ?? 0,
          orgName: userOrg?.name ?? '',
          date: moment().format('YYYY-MM-DD HH:mm:ss'),
        };

        await fetcher.put({
          path: '/deals/views/public',
          body: {
            id: Number(id),
            views: [...(data?.views ?? []), newView],
            newView,
          },
        });
      }
    }
  } catch (error) {
    $founderDealSharedUI.update({ failed: true });
    displayError(error);
  } finally {
    $founderDealShared.loadingEnd();
  }
};

export const addDealToPipeline = async (history) => {
  $founderDealSharedUI.update({ isModalLoading: true });
  const { id, teamId, rating, status } = $founderDealShared.value;
  const { organization: userOrg, id: userId } = $user.value;

  try {
    await Promise.all([
      fetcher.post({
        path: '/investorsonfounderpipeline',
        body: {
          data: {
            investor_org: { connect: { id: userOrg?.investor_org_id } },
            deal: { connect: { id } },
            pipeline_status: status,
            todos: getBaseTodos(),
          },
        },
      }),
      fetcher.post({
        path: `/dealsoninvestorpipeline/${userId}`,
        body: {
          data: {
            investor_org_id: userOrg?.investor_org_id,
            deal_id: id,
            pipeline_status: 'new',
            rating,
            team_id: +teamId || null,
          },
        },
      }),
    ]);

    history.push('/investor/pipeline');
  } catch (e) {
    displayError(e);
  } finally {
    $founderDealSharedUI.update({ isModalLoading: false });
  }
};

export const determineRedirect = () => {
  const { id: userId, organization: userOrg } = $user.value;

  let redirect = '/login';
  if (userId) {
    redirect = userOrg?.type_id === 2 ? '/investor/dashboard/' : '/founder/dashboard';
  }
  return redirect;
};

export const getSnapshotRows = () => {
  const {
    investors,
    prevRaised,
    raiseSize,
    valLastRound,
    type_of_raise: type,
    uses_of_capital: useOfCap,
  } = $founderDealShared.value;

  const rows = [
    { title: 'Size of Current Raise', value: raiseSize },
    { title: 'Valuation of last round (USD)', value: valLastRound },
    { title: 'Total Amount Previosuly Raised (USD)', value: prevRaised },
    { title: 'Type of Raise', value: type },
    { title: 'Uses of Capital', value: useOfCap },
    { title: 'Current Investors', value: investors },
  ];

  return rows;
};

export const getCompanyRows = () => {
  const { organization, founder_org: founderOrg } = $founderDealShared.value;

  const rows = [
    { title: 'Company Name', value: organization?.name },
    { title: 'Company Overview', value: founderOrg?.overview },
    { title: 'Company Website', value: organization?.website },
    { title: 'Company Location', value: organization?.location },
    { title: 'Name of Founder', value: organization?.founder },
    { title: 'Co-Founders', value: organization?.coFounders },
    { title: 'Startup Stage', value: organization?.startup_stage },
    { title: 'Monthly Recurring Revenue', value: formatMoneyNoDecimals(founderOrg?.mrr) },
    { title: 'Annual Recurring Revenue', value: formatMoneyNoDecimals(founderOrg?.arr) },
  ];

  return rows;
};

export const RATINGS = ['A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-'];

export const clearAndCloseModal = () => {
  $founderDealSharedUI.update({
    isModalVisible: false,
  });

  $founderDealShared.update({
    teamId: undefined,
    rating: undefined,
  });
};

export const AddButtonPopOver = () => {
  const { dealExistsInPipeline, isInvestor, isAngel } = $founderDealShared.value;

  // Do not show pop over if deal is not in pipeline
  if (isInvestor && !dealExistsInPipeline) {
    return <></>;
  }

  const pop = (
    <Popover className="text-dark px-xl-24 px-lg-16 py-xl-16 py-lg-8">
      <p className="fw-600 text-center mb-0">
        {isInvestor
          ? (
            <>
              This deal is already on<br />
              {isAngel ? ('your pipeline.') : (`${dealExistsInPipeline?.team?.name}'s pipeline.`)}
            </>
          )
          : (<>Only investors can add<br />deals to their pipeline.</>)}
      </p>
    </Popover>
  );

  return pop;
};

export const handleNavigateToLogin = (path, history) => {
  $founderDealShared.update({ path });
  history.push('/login');
};
