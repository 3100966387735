import { useEffect } from 'react';
import $alert from 'signals/notifier.signal';
import { Button } from 'react-bootstrap';
import $user from 'signals/User.signal';

const Notifier = () => {
  const { title, message = [], timeOut } = $alert.value;
  const messages = (!Array.isArray(message) ? [message] : message).filter(msg => !!msg);

  useEffect(() => {
    if (!messages.length) return;

    const x = setTimeout(() => {
      $alert.reset();

      return () => clearTimeout(x);
    }, timeOut);

    // eslint-disable-next-line
    return () => clearTimeout(x);
  }, [messages, timeOut, title]);

  if (messages && messages.length > 0) {
    return (
      <div className={$user.value.mode}>
        <div
          className={`bg-base-tertiary border border-${$alert.value.type === 'success' ? 'success' : 'primary'} text-center text-primary animate__animated animate__slideInDown animate__faster`}
          style={{ background: 'white', position: 'absolute', width: '100%', padding: '1rem', top: 0, zIndex: 1056, fontSize: '18px' }}
        >
          {title && <p className="p-0 m-0"><b>{title}</b></p>}
          <ul className="inline-block p-0 m-0" style={{ listStyle: 'none' }}>
            { messages && messages.length && messages.map((msg, liidx) => <li key={`err-${liidx}`}>&bull; {msg}</li>)}
          </ul>
          <Button
            className="fw-500 py-0 px-8 mt-16"
            onClick={() => $alert.reset()}
            variant={$alert.value.type === 'success' ? 'success' : 'primary'}
          >
            {$alert.value.type === 'success' ? 'Close' : 'Dismiss'}
          </Button>
        </div>
      </div>
    );
  }

  return null;
};

export default Notifier;
