import firebase from 'firebase/app';
import { getConfig } from 'config/config';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

export const firebaseConfig = {
  apiKey: getConfig('REACT_APP_FIREBASE_PUB_KEY'),
  authDomain: getConfig('REACT_APP_FIREBASE_AUTH_DOMAIN'),
  databaseURL: getConfig('REACT_APP_FIREBASE_DATABASE_URL'),
  projectId: getConfig('REACT_APP_FIREBASE_PROJECT_ID'),
  storageBucket: getConfig('REACT_APP_FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: getConfig('REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
  appId: getConfig('REACT_APP_FIREBASE_APP_ID'),
};

let app;
if (!firebase.apps.length) {
  app = firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();
const firestore = firebase.firestore();
const now = firebase.firestore.Timestamp.now();
const fbKey = `firebase:authUser:${getConfig('REACT_APP_FIREBASE_PUB_KEY')}:[DEFAULT]`;
const storage = firebase.storage(app);

const getLocalStorage = () => Object.keys(window.localStorage)
  .filter((item) => item.startsWith('firebase:authUser'))[0];

const fetchUserInfo = async (userId) => {
  try {
    const userRef = firebase.firestore().collection('users').doc(userId);

    return userRef.get().then((doc) => {
      if (doc.exists) {
        return doc.data();
      }
      return {};
    }).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const fetchMostRecentPlaidTransactions = async (orgId) => {
  try {
    const querySnapshot = await firestore
      .collection('plaidTransactions')
      .where('organizationId', '==', orgId)
      .orderBy('createdAt', 'desc')
      .limit(1)
      .get();

    if (querySnapshot.empty) {
      return null;
    }
    return querySnapshot.docs[0].data();
  } catch (error) {
    console.error('Error fetching the most recent Plaid transaction:', error);
    return null;
  }
};

export {
  firebase,
  auth,
  firestore,
  now,
  getLocalStorage,
  fbKey,
  fetchUserInfo,
  storage,
  fetchMostRecentPlaidTransactions,
};
